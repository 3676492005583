.slide {
    transform: scale(0.7);
    transition: transform 300ms;
    opacity: 0.5;
}

.prevArrow {
    position: absolute;
    top: 110%;
    left: 750px;
    z-index: 100;
    cursor: pointer;
    font-size: 2rem;
}

.nextArrow {
    position: absolute;
    top: 110%;
    right: 750px;
    z-index: 100;
    cursor: pointer;
    font-size: 1rem;
}
