@tailwind base;
@tailwind components;
@tailwind utilities;

.tsparticles {
    position: fixed;
    width: 100%;
    z-index: -1;
}
.browser-frame__button--green {
    background: #0bd5b6 !important;
}
.browser-frame__button {
    margin-left: 6px !important;
}
.browser-frame {
    border-radius: 25px !important;
}
.browser-frame__top {
    background: none !important;
}
.browser-frame__burger-line {
    visibility: hidden !important;
}
html {
    scroll-behavior: smooth;
    background-color: #0a192e;
}
.button_back {
    visibility: hidden !important;
}
